.indexpage {
	.four-boxes {
		position: relative;

		.vier_boxes {
			background-color: #fff;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			a {
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				position: absolute;
				overflow: hidden;
				display: block;
				background-color: rgba(128, 112, 112, .5);
				-webkit-transition: background-color 0.3s ease;
				-moz-transition: background-color 0.3s ease;
				transition: background-color 0.3s ease;

				.title {
					left: 30px;
					right: 30px;
					position: absolute;
					display: block;
					text-align: right;
					font-size: 35px;
					font-weight: 400;
					color: #fff;
					-webkit-transition: bottom 0.3s ease 0.4s;
					-moz-transition: bottom 0.3s ease 0.4s;
					transition: bottom 0.3s ease 0.4s;
					bottom: 30%;
					text-shadow: rgba(0, 0, 0, .4) 0 -1px 0;
					font-family: 'Barlow Condensed', sans-serif;
					line-height: 1.5;

					@media (max-width:992px) {
						line-height: 1.2;
					}
				}
			}

			.boxes_text {
				bottom: 5px;
				left: 30px;
				right: 30px;
				position: absolute;
				-webkit-transition: top 0.3s ease 0.4s;
				-moz-transition: top 0.3s ease 0.4s;
				transition: top 0.3s ease 0.4s;
			}

			.text {
				text-align: right;
				font-size: 18px;
				color: #fff;
				opacity: 0;
				-webkit-transition: opacity 0.3s ease;
				-moz-transition: opacity 0.3s ease;
				transition: opacity 0.3s ease;
			}

			.boxes {
				width: 25%;
				height: 300px;
				position: relative;
				float: left;
				display: block;
				margin-top: 4px;
				border-right: 4px solid #fff;

				hr {
					width: 0%;
					margin: 5px 0 15px auto;
					text-align: right;
					border-top: 1px solid #fff;
					border-right: 0px solid #fff;
					border-bottom: 0px solid #fff;
					border-left: 0px solid #fff;
					color: #fff;
					-webkit-transition: width 0.3s ease;
					-moz-transition: width 0.3s ease;
					transition: width 0.3s ease;
				}

				&:hover {
					.title {
						bottom: 50%;
						-webkit-transition: bottom 0.3s ease;
						-moz-transition: bottom 0.3s ease;
						transition: bottom 0.3s ease;
					}

					.boxes_text {
						top: 45%;
					}

					hr {
						width: 80%;
						-webkit-transition: width 0.3s ease 0.7s;
						-moz-transition: width 0.3s ease 0.7s;
						transition: width 0.3s ease 0.7s;
					}

					.text {
						opacity: 1;
						-webkit-transition: opacity 0.3s ease 0.4s;
						-moz-transition: opacity 0.3s ease 0.4s;
						transition: opacity 0.3s ease 0.4s;
					}
				}
			}

			.boxes_1 {
				background-image: url(RESOURCE/kacheln/Strandvillen_476x300.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.boxes_2 {
				background-image: url(RESOURCE/kacheln/Strandkorb_476x300.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.boxes_3 {
				background-image: url(RESOURCE/kacheln/Meerblick_476x300.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.boxes_4 {
				background-image: url(RESOURCE/kacheln/Hund_476x300.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}

	.beautiful-apartments {
		padding-top: 50px;
		text-align: center;

		.owl-thumbs {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: center;

			.owl-thumb-item {
				border: none;
				outline: 0 none;
				padding: 0;
				float: left;
				width: 100%;
				height: 110px;
				margin: 4px 2px;

				.userimg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.container {
			max-width: 1700px;
			width: 100%;
		}

		h2 {
			text-align: center;
			font-size: 2.2rem;
			margin: 0 0 20px;
			font-family: 'Barlow Condensed', sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: 0.2rem;
			text-transform: uppercase;
		}

		h3 {
			margin: 12px 0 18px;
			font-size: 1.25rem;
			line-height: 28px;
			font-family: 'EB Garamond', serif;
			font-weight: normal;
			color: var(--Reetlandred);
		}

		p {
			font-size: 18px;
			line-height: 28px;
			font-weight: 400;
			margin: 0 0 20px;
			color: var(--Darkgreen);
			font-family: 'EB Garamond', serif;
		}

		.divider {
			width: 100%;
			height: 1px;
			position: relative;
			clear: both;
			margin: 40px 0;
			background-color: var(--Primarycolor);
			float: left;

			&:after {
				width: 50px;
				height: 50px;
				top: -24px;
				position: absolute;
				display: block;
				padding: 10px;
				background-color: #fff;
				background-image: url(RESOURCE/img/usedomtravel-logo-white-1.png);
				background-repeat: no-repeat;
				content: "";
				background-size: 30px;
				background-position: center;
				left: 50%;
				transform: translate(-50%);
			}
		}
	}

	.index-third-section {
		.container {
			max-width: 1700px;
			width: 100%;

			.box {
				border-radius: 3px;
				margin: 0 0 30px 0;

				img {
					width: 100%;
					height: 300px;
					object-fit: cover;
					border-radius: 3px;
				}
			}

			.ideal-for-families {
				display: flex;
				width: 100%;
				flex-wrap: wrap;

				h4 {
					text-align: left;
					font-size: 40px;
					margin: 0 0 20px;
					width: 100%;
					padding: 0 15px;
					font-family: Barlow Condensed, sans-serif;
					color: var(--Themelight);
					font-weight: 600;
					letter-spacing: .2rem;
					text-transform: uppercase;
				}

				.text {
					p {
						font-size: 18px;
						line-height: 28px;
						font-weight: 400;
						margin: 0 0 20px;
						color: var(--Darkgreen);
						font-family: EB Garamond, serif;
					}
				}
			}
		}
	}

	.brief {
		padding-top: 80px;
		padding-bottom: 115px;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;


		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 40px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}


	}

	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	/* extra css after content change */
	.pt-50 {
		padding-top: 50px !important;
	}

	.container-small {
		width: 100%;
		margin: 0 auto;
		max-width: 1035px;
		padding: 0 15px;
		display: flex;
		flex-wrap: wrap;

		img {
			width: 100% !important;
		}

		h2 {
			text-align: center;
			font-size: 2.2rem;
			line-height: 50px;
			margin: 20px 0 30px;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}

		h3 {
			margin: 0 0 10px;
			font-size: 20px;
			line-height: 28px;
			font-family: EB Garamond, serif;
			font-weight: 400;
			color: var(--Reetlandred);
			text-align: center;
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				display: block;
				content: "\A0\2014\A0";
				font-size: 30px;
				font-weight: 100;
				margin: 0 15px;
				line-height: 20px;
				font-family: Barlow Condensed, sans-serif;
			}

			&:after {
				display: block;
				content: "\A0\2014\A0";
				font-size: 30px;
				font-weight: 100;
				margin: 0 15px;
				line-height: 20px;
				font-family: Barlow Condensed, sans-serif;
			}
		}

		h4 {
			color: var(--Primarycolor);
			letter-spacing: 0px;
			transition: c;
			text-align: center;
			margin: 0 0 15px;
			width: 100%;
		}

		p {
			text-align: center;
			font-size: 18px;
			line-height: 28px;
			word-spacing: 0.25em;
			font-weight: 400;
			margin: 20px 0 20px;
			width: 100%;
			color: var(--Darkgreen);

			em {
				margin-top: 15px;
				display: flex;
			}

			.btn-primary {
				text-align: center;
				background-color: #fff;
				border: 1px solid var(--Themelight);
				border-radius: 2px;
				line-height: 38px;
				color: var(--Themelight) !important;
				padding: 8px 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				font-size: 18px;
				letter-spacing: 0.05rem;
				max-width: 250px;
				margin: 0 auto;

				&:hover {
					color: var(--Reetlandred) !important;
					border: 1px solid var(--Reetlandred) !important;
				}
			}
		}

		h6 {
			margin: 0 0 10px;
			font-size: 18px;
			line-height: 24px;
			font-family: EB Garamond, serif;
			font-weight: 400;
			color: var(--Reetlandred);
			text-align: left;
			width: 100%;
		}
	}


	.divider.abstand {
		margin-top: 70px;
		margin-bottom: 70px;
	}

	p.text-left {
		text-align: left !important;
	}




	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}
}

@media (max-width: 767px) {
	.container-small {
		h2 {
			font-size: 1.875rem !important;
		}
	}

	.indexpage {
		.four-boxes {
			.vier_boxes {
				.boxes {
					width: 100%;
					border-right: 4px solid #fff;
					border-left: 4px solid #fff;
				}
			}
		}

		.index-third-section {
			.container {
				.box {
					img {
						height: auto;
					}
				}
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 480px) {
	.indexpage {
		.beautiful-apartments {
			.owl-thumbs {
				.owl-thumb-item {
					height: 40px;
				}
			}
		}
	}
}

@media all and (min-width: 481px) and (max-width: 640px) {
	.indexpage {
		.beautiful-apartments {
			.owl-thumbs {
				.owl-thumb-item {
					height: 60px;
				}
			}
		}
	}
}

@media all and (min-width: 641px) and (max-width: 767px) {
	.indexpage {
		.beautiful-apartments {
			.owl-thumbs {
				.owl-thumb-item {
					height: 70px;
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.indexpage {
		.four-boxes {
			.vier_boxes {
				.boxes {
					a {
						.title {
							font-size: 20px;
							bottom: 40%;
						}

						.text {
							font-size: 15px;
							line-height: 19px;
						}
					}
				}

				.boxes:hover {
					.title {
						bottom: 80%;
					}

					.boxes_text {
						top: 22%;
					}
				}
			}
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.indexpage {
		.four-boxes {
			.vier_boxes {
				.boxes {
					a {
						.title {
							font-size: 25px;
							bottom: 40%;
						}

						.text {}
					}
				}

				.boxes:hover {
					.title {
						bottom: 80%;
					}

					.boxes_text {
						top: 22%;
					}
				}
			}
		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1400px) {
	.indexpage {
		.four-boxes {
			.vier_boxes {
				.boxes {
					a {
						.title {
							font-size: 25px;
							bottom: 40%;
						}

						.text {}
					}
				}

				.boxes:hover {
					.title {
						bottom: 80%;
					}

					.boxes_text {
						top: 22%;
					}
				}
			}
		}
	}
}