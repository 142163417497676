@import "common.css";


.navbarheader {
	background: var(--white) !important;

	.logo {
		@media (min-width: 768px) {
			top: -15px;
		}
	}

	li.header-search {
		form {
			.form-control {
				@media (min-width:993px) {
					color: var(--Darkgreen) !important;
				}
			}

			.form-control::-webkit-input-placeholder {

				/* Edge */
				@media (min-width:993px) {
					color: var(--Darkgreen) !important;
					opacity: .5;
				}
			}

			.form-control:-ms-input-placeholder {

				/* Internet Explorer */
				@media (min-width:993px) {
					opacity: .5;
					color: var(--Darkgreen) !important;
				}
			}

			.form-control::placeholder {
				@media (min-width:993px) {
					color: var(--Darkgreen) !important;
					opacity: .5;
				}
			}
		}
	}
}

.navbarheader .logo {
	max-width: 100px !important;
}

.static-content-area p a {
	word-break: break-word;
}


.indexpage .beautiful-apartments .divider:after,
.static-view .divider:after {
	background-image: url(RESOURCE/img/logo.png) !important;
}

.indexpage p {
	margin-top: 0 !important;
}

.vo-index-body {
	.container-small {
		img {
			margin-bottom: 15px;
		}
	}
}

.navbar-scroll.navbarheader .white {
	color: var(--Darkgreen) !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.gallery-items {
	img {
		margin-bottom: 30px;
	}
}

.navbar-scroll {
	&.affix {
		.logo {
			img {
				height: 65px;
				margin-top: 10px;
			}
		}
	}
}

.navbar-scroll.navbarheader .navbar-scr-bl .dropdown .dropdown-menu {
	background-color: #eee !important;
}

.navbar-scroll.navbarheader .navbar-scr-bl .dropdown .dropdown-menu ul li {
	margin: 9px 10px !important;
}

.owl-carousel-fs2 .owl-item .item img {
	margin-bottom: 0 !important;
}

.navbar-scroll.navbarheader .navbar-scr-bl .dropdown .dropdown-menu ul li a {
	color: var(--Darkgreen) !important;
}

.indexpage {
	.container-small {
		p {
			margin: 0 0 20px;
		}

		&.display-block {
			display: block;
		}

		.side-owl-slider {}
	}

	.beautiful-apartments .owl-thumbs .owl-thumb-item.active {
		background-color: var(--Secondarycolor);
	}

	.beautiful-apartments .owl-thumbs .owl-thumb-item {
		border: none;
		outline: 0 none;
		float: left;
		width: 100%;
		height: auto;
		margin: 0 0 5px;
		text-align: left;
		padding: 8px 12px 12px !important;
		height: auto !important;
		background-color: var(--Themelight);
		color: var(--white);
		font-size: 16px;

		@media (max-width:767px) {
			font-size: 14px !important;
			margin: 0 0 4px !important;
			height: auto !important;
			padding: 5px !important;
		}
	}

	.beautiful-apartments .owl-thumbs {
		display: block !important;
	}

}

.indexpage .beautiful-apartments .owl-thumbs {
	@media (min-width:993px) {
		margin-left: 13px;
	}
}

.indexpage .beautiful-apartments .owl-thumbs:first-child {
	@media (min-width:993px) {
		margin-top: 13px;
	}
}

.vo-search-body .search-result-properties>div {
	background-color: var(--Primarycolor) !important;
}

.similar-units .unit-rest-prp .search-result-properties>div {
	background-color: var(--Primarycolor) !important;
}

/*Static page image zoom function - START*/
#imgLageplan {
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
	display: block;
	margin-left: auto;
	margin-right: auto
}

#imgLageplan:hover {
	opacity: 0.7;
}

/* Modal Content (image) */
.img-modal-content {
	margin: auto;
	display: block;
	max-width: 65%;
	padding: 20px;
}

/* Add Animation */
.modal-content,
.img-modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

.modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

.out {
	animation-name: zoom-out;
	animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(1)
	}

	to {
		-webkit-transform: scale(2)
	}
}

@keyframes zoom {
	from {
		transform: scale(0.4)
	}

	to {
		transform: scale(1)
	}
}

@keyframes zoom-out {
	from {
		transform: scale(1)
	}

	to {
		transform: scale(0)
	}
}

.box {
	.close {
		position: absolute;
		background: #fff;
		opacity: 0.7;
		color: #000;
		width: 35px;
		height: 35px;
		top: 15px;
		right: 35px;
		border-radius: 100px;
		font-size: 15px;
		font-weight: bold;

		&:hover {
			opacity: 1;
		}
	}
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}

/*Static page image zoom function - END*/